a {
  text-decoration: none;
  color: #ffc107;
}

a:active {
  text-decoration: none;
  color: #ffc107;
}

a:hover {
  background-color: none;
  color: #ffc107;
}

img {

}

.galleryImage:hover {
  cursor: pointer;
  opacity: 0.8;
}